<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-1 pb-1'>
                    <div class='header-left-section flex align-items-center justify-content-start mb-3 md:mb-0'>
                        <div class='profile relative'>
                            <img src='http://image.a1catering.com.tr/YoneticiProfil/ProfilDefault.png' width='100'
                                 alt='profile'
                                 class='border-circle border-3 border-solid' />
                            <span class='profile-badge absolute'><span class='dot block'></span></span>
                        </div>

                    </div>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger pr-3 pl-3 pt-2 pb-2' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Proje Personel Ekleme</h3>

					<p class="text-lg">
						Eklediğiniz veya daha önce eklenmiş personelin T.C. kimlik numarasını girin.<br>
                        <br>
                        <b>İşe Giriş Tarihi:</b> Personelin resmi olarak işe başlangıç tarihi doğru olarak girilmelidir. Bu bilgiye sahip değilseniz mali müşavirlikten bu bilgiyi alınız.<br>
						<br>	
                        <b>Departman:</b> Personelin çalışacağı departmanı seçiniz.<br>
                        <br>
                        <b>Maaş:</b> Bu bilgiye sahip değilseniz muhasebe ile irtibata geçiniz.<br>
                        <br>
                        <b>Diğer Maaş:</b> Bu bilgiye sahip değilseniz muhasebe ile irtibata geçiniz.<br>
                        <br>
                        <b>Banka:</b> Personelin maaşını almak istediği bankayı seçiniz.<br>
                        <br>
                        <b>IBAN:</b> Personelin beyan ettiği IBAN numarasını giriniz.<br>
					</p>                   
				</Sidebar>

				</span>

            </div>


        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <div class='grid'>

                    <div class='col-12'>


                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Eklenecek Proje</label>
                            <div class='col-12 md:col-10'>
                                <b>{{ projectName }}</b>
                            </div>
                        </div>


                        <div class='field grid mb-5' v-if='!isIdentityNumberOk'>

                            <label class='col-12 mb-2 md:col-2 md:mb-0'>T.C. Kimlik No<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>

                            <div class='col-12 md:col-8'>
                                <InputText id='identityNumber' type='text' autocomplete='off' v-model='identityNumber' maxlength='11' />
                            </div>

                            <div class='col-12 md:col-2'>
                                <Button id='checkIdentityNumber' label='Kontrol Et Getir' icon='pi pi-plus' class='mr-2 mb-2' @click='checkIdentityNumber'></Button>
                            </div>

                        </div>

                        <div class='field grid mb-5' v-if='isIdentityNumberOk'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Personel</label>
                            <div class='col-12 md:col-10'>
                                {{ employee.name }} {{ employee.surName }} - {{ employee.identityNumber }}
                            </div>
                        </div>

                        <div class='field grid mb-5' v-if='isIdentityNumberOk'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>İşe Giriş Tarihi<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class='col-12 md:col-10'>
                                <Calendar id='startDate' :showIcon='true' dateFormat='dd.mm.yy' autocomplete='off' :showButtonBar='true' v-model='dataItem.startDate' @date-select='dateSelected'></Calendar>
                            </div>
                        </div>

                        <div class='field grid mb-5' v-if='isIdentityNumberOk'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Departman<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class='col-12 md:col-10'>
                                <Dropdown id='department' v-model='dataItem.department' :options='departmentList' optionLabel='name' option-value='name' placeholder='Seçim Yapın'></Dropdown>
                            </div>
                        </div>

                        <div class='field grid mb-5' v-if='isIdentityNumberOk'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Maaş</label>
                            <div class='col-12 md:col-10'>
                                <InputNumber id='officialSalary' autocomplete='off' v-model='dataItem.officialSalary'/>
                            </div>
                        </div>

                        <div class='field grid mb-5' v-if='isIdentityNumberOk'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Diğer Maaş</label>
                            <div class='col-12 md:col-10'>
                                <InputNumber id='unOfficialSalary' autocomplete='off' v-model='dataItem.unOfficialSalary' type='text' />
                            </div>
                        </div>

                        <div class='field grid mb-5' v-if='isIdentityNumberOk'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Banka<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class='col-12 md:col-10'>
                                <Dropdown id='banka' v-model='dataItem.bankName' autocomplete='off' :options='bankList' optionLabel='name' option-value='name' placeholder='Seçim Yapın'></Dropdown>
                            </div>
                        </div>

                        <div class='field grid mb-5' v-if='isIdentityNumberOk'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>IBAN<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class='col-12 md:col-10'>
                                <InputText id='iban' v-model='dataItem.iban' type='text' autocomplete='off' maxlength='26' />
                            </div>
                        </div>
                        <div class='field grid mb-5'  v-if='isIdentityNumberOk'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Emekli mi ?</label>
                            <div class='col-12 md:col-10'>
                                <InputSwitch id='isRetired' v-model='dataItem.isRetired' />
                            </div>
                        </div>
                        <div class='field grid' v-if='isIdentityNumberOk'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                            <div class='col-12 md:col-10'>
                                <Button id='save' label='Kaydet' icon='pi pi-save' @click='save' v-bind:disabled='isProcessing'></Button>
                            </div>
                        </div>

                    </div>


                </div>

            </div>

        </div>


    </div>
</template>

<script>

import { showErrorMessage, showValidationMessage } from '@/components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import EmployeeService from '../../services/employeeService';
import ProjectEmployeeService from '../../services/projectEmployeeService';
import { getAllBanks, getDepartmentList} from '../common/commonFunctions';
import { getActiveProjectId, getActiveProjectName, normalizeDate } from '../common/commonConstantFunctions';
import SmsService from '@/services/smsService';

export default {
    _employeeService: null,
    _smsService:null,
    _bankService: null,
    _projectEmployeeService: null,
    components: { AdminToast },
    created() {
        this._employeeService = new EmployeeService();
        this._projectEmployeeService = new ProjectEmployeeService();
        this._smsService = new SmsService();
    },
    async mounted() {
        this.projectId = getActiveProjectId();
        this.projectName = getActiveProjectName();
        this.bankList = await getAllBanks();
        this.departmentList = await getDepartmentList();
    },
    data() {
        return {
            isProcessing:false,
            identityNumber: 0,
            officialHourlySalary: 0,
            unOfficialHourlySalary: 0,
            isIdentityNumberOk: false,
            projectName: '',
            projectId: 0,
            visibleLeft: false,
            employee: {},
            bankList: [],
            departmentList: [],
            dataItem: {
                projectId: 0,
                startDate: '',
                department: '',
                officialSalary: 0,
                unOfficialSalary: 0,
                insuranceDeposit: 0,
                bankName: '',
                iban: '',
                employeeId: 0,
                dailyPrice: 0,
                normalOverTimeHourlyPrice: 0,
                holidayOverTimeHourlyPrice: 0,
                officialDailyPrice:0,
                unOfficialDailyPrice:0,
                totalSalary:0
            },
            genders: [
                {
                    name: 'Erkek',
                    value: 'Erkek',
                },
                {
                    name: 'Kadın',
                    value: 'Kadın',
                },
            ],
        };
    },

    methods: {
        dateSelected() {
            this.dataItem.startDate = normalizeDate(this.dataItem.startDate);
        },

        async checkIdentityNumber() {
            let employeeResponse = await this._employeeService.getEmployeeByIdentityNumber(this.identityNumber);
            if (employeeResponse.isSuccess) {
                if (employeeResponse.data.data.length == 0) {
                    showValidationMessage(this, 'T.C. Kimlik Numarası Sistemde Bulunamadı');
                    return;
                }
                else if(employeeResponse.data.data[0].isBanned == true)
                {
                    showValidationMessage(this, 'Bu Kişi YASAKLI Olduğu İçin Projeye Eklenemez');
                    return true;
                }
                else {
                    this.employee = employeeResponse.data.data[0];

                    if ((await this.employeeRegisteredAnotherProject(this.employee.id)) == true) {
                        return;
                    } else {
                        this.dataItem.projectId = this.projectId;
                        this.dataItem.employeeId = this.employee.id;
                        this.isIdentityNumberOk = true;
                    }
                }
            } else {
                showErrorMessage(this, 'T.C. Kimlik Numarası Kontrolü Sırasında Hata Oluştu');
            }
        },

        async employeeRegisteredAnotherProject(employeeId) {
            let projectEmployeeResponse = await this._projectEmployeeService.getActiveEmployeeWithIdentityNumber(employeeId);
            if (projectEmployeeResponse.isSuccess) {
                if (projectEmployeeResponse.data.length > 0) {
                    if(projectEmployeeResponse.data[0].projectId == this.projectId)
                    {
                        showValidationMessage(this, 'T.C. Kimlik Numarası Bu Projede Zaten Kullanılıyor');
                        return true;
                    }

                    else {
                        showValidationMessage(this, 'T.C. Kimlik Numarası '+ projectEmployeeResponse.data[0].project.name +' Projesinde Kullanılıyor');
                        return true;
                    }

                }
            } else {
                showErrorMessage(this, 'T.C. Kimlik Numarası Kontrolü Sırasında Hata Oluştu');
                return true;
            }
            return false;
        },
        async save() {
            this.isProcessing = true;
            if (this.validateForm()) {
                this.dataItem.startDate = normalizeDate(this.dataItem.startDate);
                let insertResponse = await this._projectEmployeeService.insertEmployeeToProject(this.dataItem);
                if (insertResponse.isSuccess) {
                    await this._smsService.sendNewEmployeeSms(insertResponse.data.id);
                    await this.$router.push({
                        name: 'editProjectEmployee',
                        params: { projectEmployeeId: insertResponse.data.id, employeeId: insertResponse.data.employeeId },
                    });
                } else {
                    showErrorMessage(this, 'Çalışan Eklemede Sorun İle Karşılaşıldı');
                }
            }
            this.isProcessing = false;
        },

        formatPrice(value) {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        },
        validateForm() {

            if (this.dataItem.startDate == '') {
                showValidationMessage(this, 'İşe Giriş Tarihi Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.department == '') {
                showValidationMessage(this, 'Departman Alanı Boş Bırakılamaz');
                return false;
            }


            if (this.dataItem.bankName == '') {
                showValidationMessage(this, 'Banka Seçimi Yapınız');
                return false;
            }

            if (this.dataItem.iban == '') {
                showValidationMessage(this, 'IBAN Alanı Boş Bırakılamaz');
                return false;
            }

            return true;
        },
    },

};

</script>


<style scoped>

</style>
